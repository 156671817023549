<template>
  <div id="bg">
    <div class="search">

      <div class="search-box">
        分类:
        <el-select v-model="search.type">
          <el-option :value="-1" label="全部"></el-option>
          <el-option v-for="item of periodsType" :key="item" :value="item.value" :label="item.label"></el-option>
        </el-select>
      </div>
      <div class="search-box">
        <el-button type="primary" size="medium" @click="showAddDialog()">添加</el-button>
        <el-button type="primary" size="medium" @click="getList()">查询</el-button>
      </div>
    </div>
    <el-table :data="tableData" :header-cell-style="{ background: '#F7F8FA' }">
<!--      <el-table-column prop="id" label="ID" align="center" :show-overflow-tooltip="true"></el-table-column>-->
      <el-table-column prop="type" label="类型" align="center" :show-overflow-tooltip="true">
        <template #default="scope">
          {{getPeriodsType(scope.row.type)}}
        </template>
      </el-table-column>
      <el-table-column prop="periods" label="期数" align="center" :show-overflow-tooltip="true">
        <template #default="scope">
          {{scope.row.type == 0?scope.row.periods:"-"}}
        </template>
      </el-table-column>
      <el-table-column prop="title" label="标题" align="center" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column prop="counter" label="视频数量" align="center" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column prop="status" label="状态" align="center" :show-overflow-tooltip="true">
        <template #default="scope">
          <el-switch disabled v-model="scope.row.status" :active-value="1" :inactive-value="0"></el-switch>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="200px" align="center">
        <template #default="scope">
          <span class="operation" @click="showAddVideoDialog(scope.row,scope.$index)">添加视频</span>
          <span class="operation" v-if="scope.row.type == 0" @click="showAddDialog(scope.row,scope.$index)">编辑</span>
          <span class="operation" @click="onDelSubmit(scope.row,scope.$index)">删除</span>
        </template>
      </el-table-column>
    </el-table>
    <page :pager="pager" @query="getList()" />
  </div>
  <el-dialog v-model="dialogAdd" width="400px" :title="title">
    <el-form>
      <el-form-item label="分类">
        <el-select v-model="form.type">
          <el-option :value="-1" label="全部"></el-option>
          <el-option v-for="item of periodsType" :key="item" :value="item.value" :label="item.label"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="期数" v-if="form.type == 0">
        <el-input type="number" :disabled="!this.isAdd" placeholder="请输入期数" v-model="form.periods"></el-input>
      </el-form-item>
      <el-form-item label="标题"  v-if="form.type == 0">
        <el-input placeholder="请输入期数标题" v-model="form.title"></el-input>
      </el-form-item>
      <el-form-item label="状态"  v-if="form.type == 0">
        <el-switch :active-value="1" :inactive-value="0" v-model="form.status"></el-switch>
      </el-form-item>
      <el-form-item label="">
        <el-button @click="onSubmit">{{isAdd?"添加":"修改"}}</el-button>
      </el-form-item>
    </el-form>
  </el-dialog>
  <el-dialog v-model="dialogAddVideo" :title="addVideoTitle" width="1000px">
    <div class="search">
      <div class="search-box">
        <span class="search-box-title">状态：</span>
        <el-select v-model="filterSearch.status" class="width_100" filterable placeholder="全部" size="medium" clearable >
          <el-option label="全部" :value="-1"></el-option>
          <el-option label="未添加" :value="0"></el-option>
          <el-option label="已添加" :value="1"></el-option>
        </el-select>
      </div>
      <div class="search-box">
        <span class="search-box-title">账号：</span>
        <el-input class="width_150" v-model="filterSearch.username" placeholder="输入视频账号搜索" size="small" />
      </div>
      <div class="search-box">
        <span class="search-box-title">视频标题：</span>
        <el-input v-model="filterSearch.title" class="width_150" placeholder="输入视频标题搜索" size="small" /> <el-button @click="getPeriodsVideos">查询</el-button>
        <el-button v-if="multiRows.length > 0" @click="addMultiSelect">批量添加</el-button>
        <el-button v-if="multiRows.length > 0" @click="delMultiSelect">批量删除</el-button>
      </div>
    </div>
    <div>
      <el-table :data="videoList" @selection-change="handleSelection" :header-cell-style="{ background: '#F7F8FA' }" height="538" >
        <el-table-column type="selection" align="center" :show-overflow-tooltip="true"></el-table-column>
        <el-table-column prop="id" label="ID" width="100px" align="center" :show-overflow-tooltip="true"></el-table-column>
        <el-table-column prop="username" label="账号" align="center" :show-overflow-tooltip="true"></el-table-column>
        <el-table-column prop="nickname" label="昵称" align="center" :show-overflow-tooltip="true"></el-table-column>
        <el-table-column prop="title" label="标题" align="center" :show-overflow-tooltip="true"></el-table-column>
        <el-table-column prop="status" label="状态" align="center" :show-overflow-tooltip="true">
          <template #default="scope">
            <span style="color:green" v-if="scope.row.status == 1">已发布</span>
            <span style="color:red" v-else >未发布</span>
          </template>
        </el-table-column>
        <el-table-column width="200px" align="center">
          <template #default="scope">
            <span class="operation" style="color:green" v-if="scope.row.is_add == 0" @click="addVideoToPeriods(scope.row.id,scope.$index)">添加</span>
            <span v-else @click="removePeriodsVideo(scope.row)" style="color:red">删除</span>
          </template>
        </el-table-column>
      </el-table>
      <page :pager="page2" @query="getPeriodsVideos()" />

    </div>
    <template #footer>
        <span class="dialog-footer">
          <el-button type="primary" @click="dialogAddVideo=false">关闭</el-button>
        </span>
    </template>
  </el-dialog>
</template>

<script>
import page from "@/components/page";
import httpClient from "@/config/httpClient";
export default {
  name: "videoPeriods",
  components: {
    page,
  },
  data() {
    return {
      filterSearch:{
        username:"",
        title:"",
        status:0, // 默认未添加的视频
      },
      videoList:[],
      tableData:[],
      periodsType:[
        {value:0,label:"每周必看"},
        {value:1,label:"新晋宝藏"},
        {value:2,label:"历史经典"},
      ],
      search:{
        periods:null,
        title:"",
        type:-1,
      },
      pager:{row:10,page:1,total:0},
      page2:{row:10,page:1,total:0},
      dialogAdd:false,
      title:"添加",
      form:{
        id:0,
        periods:0,
        title:"",
        status:1,
        type:0,
      },
      isAdd:true,
      curIndex:0,
      dialogAddVideo:false,
      addVideoTitle:"添加视频",
      multiRows:[]
    }
  },
  mounted() {
    this.getList()
  },
  methods:{
    getPeriodsType(type){
      for(const row of this.periodsType){
        if (row.value == type){
          return row.label
        }
      }
      return `未知分类:${type}`;
    },
    removePeriodsVideo(row){
      this.$confirm(
          `删除视频:${row.title}`,
          "提示",
          { confirmButtonText: "确定", cancelButtonText: "取消", type: "warning" }
      ).then(()=>{
        httpClient("periodsDelVideos").post({id:this.row.id,video_ids:row.id}).then((res)=>{
          if (res.code == 0){
            this.getPeriodsVideos()
          }else{
            this.$message({message:res.msg,type:"error"})
          }
        })
      })
    },
    delMultiSelect(){
      if (this.multiRows.length == 0){
        return this.$message({message:"请选择视频",type:"error"})
      }
      let ids = [];
      for(const video of this.multiRows){
        ids.push(video.id)
      }
      if (ids.length == 0){
        return
      }
      httpClient("periodsDelVideos").post({id:this.row.id,video_ids:ids.join(",")}).then((res)=>{
        if (res.code == 0){
          this.getPeriodsVideos()
        }else{
          this.$message({message:res.msg,type:"error"})
        }
      })
    },
    addMultiSelect(){
      if (this.multiRows.length == 0){
        return this.$message({message:"请选择视频",type:"error"})
      }
      let ids = [];
      for(const video of this.multiRows){
        if (video.is_add == 0){
          ids.push(video.id)
        }
      }
      if (ids.length == 0){
        return
      }
      httpClient("periodsAddVideos").post({id:this.row.id,video_ids:ids.join(",")}).then((res)=>{
        if (res.code == 0){
          for(var i in this.multiRows){
            this.multiRows[i].is_add = 1
          }
        }else{
          this.$message({message:res.msg,type:"error"})
        }
      })
    },
    handleSelection(rows){
      this.multiRows = rows
    },
    addVideoToPeriods(id,index){
      let param = {
        id:this.row.id,
        video_ids:id
      }
      httpClient("periodsAddVideos").post(param).then((res)=>{
        if (res.code == 0){
          this.videoList[index].is_add = 1
        }else{
          this.$message({message:res.msg,type:"error"})
        }
      })
    },
    getPeriodsVideos(){
      let param = this.filterSearch
      param.pageid = this.page2.page -1
      param.pcount = this.page2.row
      param.id = this.row.id
      httpClient("periodsVideoList").post(param).then((res)=>{
        if (res.code == 0){
          this.videoList = res.data.list
          if (this.page2.page == 1){
            this.page2.total = res.data.total
          }
        }else{
          this.$message({message:res.msg,type:"error"})
        }
      })
    },
    showAddVideoDialog(row,index){
      this.dialogAddVideo = true
      this.row = row
      this.curIndex = index
      this.addVideoTitle = `每周必看,第${row.periods}期, 视频列表`
      this.getPeriodsVideos()
    },
    onDelSubmit(row,index){
      this.$confirm(
          `删除期数:${row.periods}`,
          "提示",
          { confirmButtonText: "确定", cancelButtonText: "取消", type: "warning" }
      ).then(()=>{
        httpClient("periodsDel").post({id:row.id}).then((res)=>{
          if (res.code == 0){
            this.tableData.splice(index,1)
          }else{
            this.$message({message:res.msg,type:"error"})
          }
        })
      })
    },
    showAddDialog(row,curIndex){
      if (!row){
        this.title = "添加"
        this.isAdd = true
        this.form = {
          id:0,
          title:"",
          periods: 0,
          status: 1,
          type:0,
        }
      }else{
        this.curIndex = curIndex;
        this.title = '编辑'
        this.isAdd = false
        for(var i in row){
          this.form[i] = row[i]
        }
      }
      this.dialogAdd = true
    },
    onSubmit(){
      let ac = this.isAdd?"periodsAdd":"periodsEdit"
      if (this.form.type != 0){
        this.form.title = this.getPeriodsType(this.form.type)
      }
      httpClient(ac).post(this.form).then((res)=>{
        if (res.code == 0){
          this.dialogAdd = false
          if (this.isAdd){
            this.getList()
          }else{
            for(var key in this.form){
              this.tableData[this.curIndex][key] = this.form[key]
            }
          }
        }else{
          this.$message({message:res.msg,type:"error"})
        }
      })
    },
    getList(){
      let param = this.search
      param.pageid = this.pager.page -1
      param.pcount = this.pager.row
      httpClient("periodsList").post(param).then((res)=>{
        if (res.code == 0){
          this.tableData = res.data.list
          if (this.pager.page == 1){
            this.pager.total = res.data.total
          }
        }else{
          this.$message({message:res.msg,type:"error"})
        }
      })
    }
  }
};
</script>

<style scoped>
</style>
